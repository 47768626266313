<template>
	<div>
		<Header title="发送影城通知" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
		<a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 6, xxl: 5}"
			:wrapperCol="{span: 14, xxl: 13 }">
			
			<a-form-item name="cinemaId" label="所属影院" :rules="[{ required: true, message: '必选项不允许为空' }]">
				<a-select placeholder="请选择所属影院" v-model:value="modelRef.cinemaId">
					<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
				</a-select>
			</a-form-item>

      <a-form-item label="通知用户" name="notice" :rules="[{ required: true, message: '必选项不允许为空' }]">
        <a-radio-group v-model:value="modelRef.notice">
          <a-radio :value="1">全部用户</a-radio>
          <a-radio :value="2">收藏影院用户</a-radio>
        </a-radio-group>
      </a-form-item>

			<a-form-item label="通知标题" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-input v-model:value="modelRef.title" placeholder="请输入通知标题"></a-input>
			</a-form-item>

      <a-form-item label="通知内容" name="content" :rules="[{required: true, message: '必填项不允许为空'}]">
        <a-textarea :autosize="{ minRows: 6, maxRows: 99999 }" v-model:value="modelRef.content"></a-textarea>
      </a-form-item>

      <a-form-item label="发送时间" :rules="[{required: true, message: '必填项不允许为空'}]">
        <a-col :xl="6" :lg="7" :md="8" :sm="24">
          <a-form-item>
            <a-date-picker
              style="width: 200px"
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              v-model="modelRef.startDate"
            />
          </a-form-item>
        </a-col>
      </a-form-item>

			<a-row>
				<a-col :span="24" style="margin-top: 20px;text-align: center;">
					<a-button type="primary" html-type="submit">提交</a-button>
					<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
				</a-col>
			</a-row>
		</a-form>
		</a-spin>
	</div>
</template>

<script>
	// import dayjs from 'dayjs';
  import Header from '@/components/header/header.vue';

  export default {
		components: {
      Header
		},
		props: {
			id: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				loading: false,
				cinemaAllList: [],
				modelRef: {

				},
			}
		},
		watch: {

		},
    created() {

    },
    methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			}
		}
	}
</script>

<style scoped>

</style>