<template>
  <div v-show="!showModal">
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form">
        <!-- 搜索栏 -->
        <a-row>
          <a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
            <a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" allow-clear style="width: 180px;">
              <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="通知标题" name="title" class="ui-form__item">
            <a-input v-model:value="formState.title" placeholder="请输入通知标题"></a-input>
          </a-form-item>

          <a-form-item label="接收人员" name="receivePeople" class="ui-form__item">
            <a-select v-model:value="formState.receivePeople" placeholder="请选择接收人员" allow-clear style="width: 180px;">
              <a-select-option :value="1">全部用户</a-select-option>
              <a-select-option :value="2">收藏影院用户</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="发送时间"  name="time" class="ui-form__item">
            <a-range-picker v-model:value="formState.time"></a-range-picker>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button v-permission="['operation_message_list_send']" type="primary" @click="onSend">发送通知</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
              style="margin-right: 10px"
              type="primary"
              html-type="submit"
            >搜索</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" :rowKey="record => record.key"
                 :scroll="{ x: 950 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'createTime'">
              {{ transDateTime(record.createTime) }}
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>

  </div>
  <temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
</template>

<script>
import temp from './temp.vue'
import {
  Icon
} from '@/components/icon/icon.js';
import { getCinemaAllList } from '@/service/modules/cinema.js';
export default {
  components: {
    Icon,
    temp
  },
  data() {
    return {
      showModal: false,
      isEdit: false,
      isSee: false,
      id: 0,
      loading: false,
      cinemaAllList: [],
      formState: {},
      columns: [{
        title: '所属影院',
        dataIndex: 'cinema',
        width: 100
      }, {
        title: '通知标题',
        dataIndex: 'title',
        width: 100
      }, {
        title: '接收人员',
        dataIndex: 'receivePeople',
        width: 100
      }, {
        title: '发送时间',
        dataIndex: 'time',
        width: 100
      }],
      list: [{
        key: 1,
        cinema: '影院1',
        title: '标题1',
        receivePeople: '全部用户',
        time: '2023-05-05 12:12:12'
      }, {
        key: 2,
        cinema: '影院1',
        title: '标题1',
        receivePeople: '全部用户',
        time: '2023-05-05 12:12:12'
      }, {
        key: 3,
        cinema: '影院1',
        title: '标题1',
        receivePeople: '全部用户',
        time: '2023-05-05 12:12:12'
      }],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.getAllCinemaList();
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.time = [];
      this.time1 = [];
    },
    async getAllCinemaList() {
      this.cinemaAllList = [];
      let ret = await getCinemaAllList({})
      if (ret.code === 200) {
        this.cinemaAllList = ret.data;
      }
    },
    onSend() {
      this.id = 0;
      this.isEdit = false;
      this.isSee = false;
      this.showModal = true;
    },
    onBack(isRef) {
      this.showModal = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
